// material-ui
import { Chip, Stack, Typography } from '@mui/material';
import config from '../../config';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo: React.FC = (): JSX.Element => {
    return (
        <Stack direction="row" spacing={2} padding={2} style={{ backgroundColor: '#0080FF' }}>
            <img src="/logo.png" alt="Promente" height="30" />
            <Typography variant="h4" fontFamily="Public Sans" style={{ color: 'white' }}>
                {config.reactAppName}
            </Typography>
            <Chip
                label={config.reactAppVersion}
                style={{ color: 'white' }}
                size="small"
                sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem' } }}
            />
        </Stack>
    );
};

export default Logo;
