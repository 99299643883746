import { CloudServerOutlined, CreditCardOutlined, LoginOutlined, PhoneOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons';
import { SpeedDialIcon } from '@mui/material';
import { Route } from 'apis/types';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
import { StateType } from 'store/reducers';

// ==============================|| ROUTING RENDER ||============================== //

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthReset = Loadable(lazy(() => import('pages/authentication/Reset')));

// render - promente
const Assistances = Loadable(lazy(() => import('pages/ra/Assistances/Overview')));
const Customers = Loadable(lazy(() => import('pages/ra/Customers/Overview')));

export const appRoutes = (t: TFunction<'translation', undefined>): Route[] => {
    return [
        {
            requireAuth: true,
            children: [
                {
                    element: <Navigate to="/assistenze" />,
                    path: '*'
                },
                {
                    element: <Navigate to="/assistenze" />,
                    path: '/'
                }
            ]
        },
        {
            requireAuth: false,
            children: [
                {
                    element: <Navigate to="/login" />,
                    path: '*'
                },
                {
                    element: <Navigate to="/login" />,
                    path: '/'
                }
            ]
        },
        {
            title: t('nav_group_authentication'),
            requireAuth: false,
            children: [
                {
                    path: '/',
                    element: <MinimalLayout />,
                    children: [
                        {
                            id: 'login',
                            title: t('nav_item_login'),
                            element: <AuthLogin />,
                            icon: LoginOutlined,
                            path: 'login'
                        },
                        {
                            id: 'signup',
                            title: t('nav_item_signup'),
                            element: <AuthRegister />,
                            icon: ProfileOutlined,
                            path: 'signup'
                        },
                        {
                            id: 'reset',
                            element: <AuthReset />,
                            path: 'reset'
                        }
                    ]
                }
            ]
        },
        {
            title: t('nav_group_menu'),
            requireAuth: true,
            children: [
                {
                    path: '/',
                    element: <MainLayout />,
                    children: [
                        {
                            id: 'assistenze',
                            title: 'Assistenze',
                            element: <Assistances />,
                            icon: CloudServerOutlined,
                            path: 'assistenze'
                        },
                        {
                            id: 'clienti',
                            title: 'Clienti',
                            element: <Customers />,
                            icon: CloudServerOutlined,
                            path: 'clienti'
                        }
                    ]
                }
            ]
        }
    ];
};

const reduceRoutes = (routes: Route[], accessToken?: string) => {
    return routes.reduce((accumulator, element) => {
        const route = Object.assign({}, element) as Route;
        if ((accessToken && route.requireAuth !== false) || (!accessToken && !route.requireAuth)) {
            delete route.requireAuth;
            if (route.children?.length) {
                route.children = reduceRoutes(route.children, accessToken);
            }
            if (route.path && !route.external) {
                accumulator.push(route);
            } else if (route.children?.length) {
                accumulator.push(...route.children);
            }
        }
        return accumulator;
    }, [] as Route[]);
};

const Routes: React.FC = (): React.ReactElement | null => {
    const [t] = useTranslation();
    const { accessToken } = useSelector((state: StateType) => state.auth);
    return useRoutes(reduceRoutes(appRoutes(t), accessToken));
};

export default Routes;
